import Button from "@/components/button";
import Input from "@/components/input";
import Dropdown from "@/components/dropdown";
import TextArea from "@/components/text-area";
import {caseApi} from "../../main.js";
import WorkflowDesigner from "@/components/workflowdesigner";

export default {
    name: "workflow",
    components: {
        "neo-button": Button,
        "neo-input": Input,
        "neo-dropdown": Dropdown,
        "neo-text-area": TextArea,
        WorkflowDesigner,
    },
    props: [],
    data() {
        return {
            workflowControl: {
                name: "",
                description: "",
                default_workflow: "",
            },
            default_workflows: [],
            default_workflows_names: [],
            workflowFilter: "",
            workflows: [
                {id: "wk1", name: "demo workflow 1", description: "default workfolw 1"},
                {id: "wk2", name: "demo workflow 1", description: "default workfolw 1"},
                {id: "wk3", name: "demo workflow 1", description: "default workfolw 1"},
                {id: "wk4", name: "demo workflow 1", description: "default workfolw 1"},
                {id: "wk5", name: "demo workflow 1", description: "default workfolw 1"},
                {id: "wk6", name: "demo workflow 1", description: "default workfolw 1"},
                {id: "wk7", name: "demo workflow 1", description: "default workfolw 1"},
                {id: "wk8", name: "demo workflow 1", description: "default workfolw 1"},
                {id: "wk9", name: "demo workflow 1", description: "default workfolw 1"},
            ],
            selectedWorkflow: {},
        };
    },
    computed: {},
    async mounted() {
        this.selectedWorkflow = this.workflows[0];

        let default_workflows_resp = await caseApi.get("/api/v1/workflows/defaults");
        this.default_workflows = default_workflows_resp.data;
        for (let item of this.default_workflows) {
            this.default_workflows_names.push(item.name);
        }
    },
    methods: {
        async saveWorkflow() {
            var body = JSON.parse(JSON.stringify(this.workflowControl));
            for (let item of this.default_workflows) {
                if (item.name === this.workflowControl.default_workflow) {
                    body.default_workflow = item.id;
                }
            }
            let resp = await caseApi.post("/api/v1/workflows", body);
        },
        onSelectWorkflow(workflow) {
            this.selectedWorkflow = workflow;
        },
    },
};
