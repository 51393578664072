import {Network, DataSet} from "vis-network/standalone";

export default {
    name: "WorkflowDesigner",
    props: ["workflow"],
    data() {
        a: "";
    },
    methods: {},
    mounted() {},
};
